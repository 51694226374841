<template>
    <div class="guidelines">
        <div class="topBox">
            <p class="guidelinesTitle"><span class="payType" v-if="planInfo.priceType === 1">付费</span>
                <span class="payType" v-else>免费</span>
                {{planInfo.name}}</p>
            <p v-if="planInfo.endTime"><img src="../../assets/home/time.png" alt=""
                    style="width: 15px; margin-right: 5px;" />{{planInfo.endTime}}</p>
            <p v-if=" planInfo.priceType === 1">
                <template v-if="planInfo.discountPrice">
                原价:￥<span style="text-decoration:line-through;display: inline-block;margin-right: 25px;">{{planInfo.originalPrice}}</span>折扣价:<span class="redPrice">￥{{planInfo.discountPrice}}</span></template>
                <template v-else>
                    价格：<span class="redPrice">￥{{planInfo.originalPrice}}</span>
                </template>
            </p>
            <!-- <p v-else>价格：<span class="redPrice">￥{{planInfo.originalPrice}}</span></p> -->
            <!-- <p class="guidelinesTitle">{{planInfo.name}}</p> -->
        </div>
        <!-- 指导语 -->
        <div class="region">
            <div class="rg">
                <div>
                    <div class="text">
                        <p>{{ planInfo.memo }}</p>
                    </div>
                </div>
                <button class="sure" @click="payMoney"
                    v-if="planInfo.ordersStatus === 0 && planInfo.priceType === 1">确认支付</button>
                <button class="sure" @click="todo" v-else>开始测评</button>
                <!-- <button class="sure" @click="todo" v-if="planInfo.ordersStatus === 1 || planInfo.priceType === 1">开始测评</button>
                <button class="sure" @click="payOrder" v-else>确认支付</button> -->
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible"  :show-close="false" :destroy-on-close="true"
            @closed="closeTimer">
            <div class="dialogBox">
                <div class="containerBox">
                    <span style="font-size:18px; font-weight: 600;">微信支付</span>
                    <div id="qrcode" ref="qrCodeUrl" style="margin-top:30px">
                    </div>
                    <span
                        style="display:inline-block; width:200px; box-sizing: border-box; height: 60px;border:1px solid #eee; line-height: 60px; text-align: center; margin-top: 30px;">请使用微信扫一扫进行支付</span>
                </div>

                <img src="../../assets/home/weChatPhone.png" alt=""  class="payImg">
            </div>

            <!-- <span>这是一段信息</span> -->
            <!-- <span slot="footer" class="dialog-footer">
                
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';//引入生成二维码插件
import { getCode, payStatus, payTel, payWeChat } from '@/api/payOrder.js'
import { getGauge } from '@/api/evaluation'
export default {
    data() {
        return {
            info: {
                guidelinesList: [],
                guidelines: ''
            },
            planInfo: null,
            userId: null,
            orderNum: null,
            timer: 0, // 定时器，用于查询用户的支付状态
            dialogVisible: false, // 二维码模态框
            prepayId: null,
            patientId: null //用户id， 判断是否是操作员给用户答题
        }
    },
    methods: {
        //支付成功开始测评
        todo() {
            // this.$router.push({
            //     path: '/examination'
            // })
            if (this.planInfo.isMerge === 1) {
                this.$router.push({
                    path: '/home/guidelines',
                });
            } else {
                this.$router.push({
                    path: '/home/gauge',
                });
            }
        },
        // 点击支付 点击支付时获取支付二维码 PC端支付
        async payOrder() {
            const res = await getCode(this.planInfo.id, { userId: localStorage.getItem('patientId') });
            console.log(res);
            if (res.code === 200) {
                // 打开二维码模态框
                this.dialogVisible = true;
                // 生成动态二维码
                this.creatQrCode(res.data.codeUrl);
                this.orderNum = res.data.ordersNumber;
                // 定时器发起查询用户的支付状态
                this.timer = setInterval(() => {
                    this.queryOrderStatus()
                }, 3000);
            }
        },
        // 动态生成二维码
        creatQrCode(val) {
            this.$nextTick(() => {
                this.$refs.qrCodeUrl.innerHTML = '';//二维码清除
                new QRCode(this.$refs.qrCodeUrl, {
                    text: val, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            });
        },
        // 查询用户支付状态
        async queryOrderStatus() {
            const res = await payStatus(this.orderNum);
            console.log(res);
            // console.log(res)
            // 如果用户支付成功
            if (res.code === 2) {
                // 如果用户支付成功则清除查询的定时器
                clearInterval(this.timer);
                // console.log('支付成功！');
                this.$message({
                    showClose: true,
                    message: '支付成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    this.planInfo.ordersStatus = 1;
                    localStorage.setItem('planInfo', JSON.stringify(this.planInfo));
                    this.dialogVisible = false;
                }, 2000);

                // setTimeout(() => {

                // }, 3000);
            }
        },
        // payOrderTel() {
        //     location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb47a4ef3fdaf959c&redirect_uri=xbssc.zhihanyixing.com%2Findex&response_type=code&scope=snsapi_userinfo&state=d2407c65453b47dcaa93075fafaaecb3#wechat_redirect'
        // },
        // 关闭模态框时，关闭定时器
        closeTimer() {
            clearInterval(this.timer)
        },
        // 判断是否是手机
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        //支付
        async payMoney() {
            // 在支付前还需要判断是否是操作员为用户答题
            console.log(this.patientId)
            if(this.patientId !== "null" && this.patientId) {
                this.payOrder();
            } else {
                if (this._isMobile()) {
                // openid  先判断用户是否是微信授权登录
                // console.log(JSON.parse(localStorage.getItem('userInfo')).openid)
                // if (JSON.parse(localStorage.getItem('userInfo')).openid) {
                // console.log('手机端');
                //判断是否是微信浏览器
                var ua = navigator.userAgent.toLowerCase();

                // 判断是否为微信内置浏览器
                if (typeof WeixinJSBridge === "undefined") {
                    // this.$message({
                    //     showClose: true,
                    //     message: '非内置微信浏览器'
                    // });
                    // console.log(111)
                    // 非内置微信浏览器
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                    }
                    // if (ua.match(/MicroMessenger/i) == "micromessenger"){

                    // } else {

                    // }
                    const res = await payTel(this.planInfo.id);
                    console.log(res);
                    if (res.code === 200) {
                        window.location.href = res.data.h5_url;
                        this.timer = setInterval(() => {
                            this.queryOrderStatus()
                        }, 3000);
                    }
                }
                else {
                    // this.$message({
                    //     showClose: true,
                    //     message: '微信浏览器'
                    // });
                    // console.log(222)
                    // 1.是微信内置浏览器

                    // 微信内置浏览器
                    if (JSON.parse(localStorage.getItem('userInfo')).openid) {
                        // H5支付需要用户的openID
                        // 1.先获取支付prepayid
                        const res = await payWeChat(this.planInfo.id);
                        console.log(res);
                        if (res.code === 200) {
                            this.prepayId = res.data;
                            this.orderNum = res.data.ordersNumber;
                            this.onBridgeReady();
                        } else {
                            // alert('prepayId获取失败');
                            this.$message({
                                showClose: true,
                                message: 'prepayId获取失败！' + res.msg,
                                type: 'error'
                            });
                        }

                    } else {
                        this.$message({
                            showClose: true,
                            message: '请使用微信授权登录！'
                        });
                    }

                }
                // } else {
                //     this.$message({
                //         showClose: true,
                //         message: '请使用微信授权登录！'
                //     });
                // }

            } else {
                // alert("pc端");
                this.payOrder();
            } 
            }
        },
        onBridgeReady() {
            //     this.$message({
            //             showClose: true,
            //             message: '微信内置浏览器！'
            //         });
            this.timer = setInterval(() => {
                this.queryOrderStatus()
            }, 3000);
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": this.prepayId.appId,     //公众号ID，由商户传入     
                "timeStamp": this.prepayId.timeStamp,         //时间戳，自1970年以来的秒数     
                "nonceStr": this.prepayId.nonceStr, //随机串     
                "package": this.prepayId.package,
                "signType": "RSA",         //微信签名方式：     
                "paySign": this.prepayId.paySign, //微信签名 
                // success: function (res) {
                //     if (res.err_msg == "get_brand_wcpay_request:ok") {
                //         // alert('支付成功！');
                //         this.$message({
                //             showClose: true,
                //             message: '支付成功！',
                //             type: 'success'
                //         });
                //         this.planInfo.ordersStatus = 1;
                //         localStorage.setItem('planInfo', JSON.stringify(this.planInfo));
                //         this.dialogVisible = false;
                //         window.location.reload();
                //         // 使用以上方式判断前端返回,微信团队郑重提示：
                //         //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                //     } else {
                //         this.$message({
                //             showClose: true,
                //             message: '支付失败！',
                //             type: 'error'
                //         });
                //     }
                // },
            });


        }



    },
    created() {
        this.planInfo = JSON.parse(localStorage.getItem('planInfo'));
        // console.log(this.planInfo);
        clearInterval(this.timer);
        // console.log(typeof(localStorage.getItem('patientId')))
        this.patientId = localStorage.getItem('patientId');
    },
    mounted() {

    },
}
</script>

<style lang="scss" scoped>
.guidelines {
    
    .dialogBox{
        display: flex; 
        justify-content: space-between;
        padding: 0px 30px;
    }

    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    // height: 100%;
    // display: flex;
    // justify-content: center;
    .topBox {
        // height: 100px;
        background: var(--banner-color);
        box-sizing: border-box;
        font-size: 16px;
        .redPrice{
            color: rgba(191, 28, 28, 1);
        }

        // p {
        //     margin: 0px;
        //     // margin-top: 15px;
        // }

        .payType {
            display: inline-block;
            width: 56px;
            height: 24px;
            background: #66B3F9;
            border-radius: 2px;
            color: white;
            text-align: center;
            font-size: 16px;
            margin-right: 5px;
        }

        // display: flex;
        // justify-content: center;
        // align-items: center;

        .guidelinesTitle {
            // width: 80%;
            font-size: 16px;
            font-weight: 600;
            color: #303030;
            margin-top: 0px;
            // padding-top: 15px;
            // text-align: center;


        }
    }

    .region {
        .rg {
            width: 80%;
            margin: auto;
        }

        p {
            margin: 0;
            padding: 0;

        }



        .text {
            border: 1px solid rgba(221, 221, 221, 1);
            padding: 10px;
            margin-top: 20px;

            p {
                font-size: 16px;
                font-weight: 400;
                color: #7D7D7D;
            }
        }

        .sure {
            margin-top: 40px;
            width: 100%;
            font-size: 16px;
            // width: 650px;
            height: 40px;
            background: var(--custom-color);
            border-radius: 4px;
            border: none;
            color: white;
        }
    }
}

@media screen and (min-width: 751PX) {
    ::v-deep .el-dialog{
        width: 700px !important;
    }
    .containerBox{
        padding-top: 50px;
    }
    .payImg{
        height: 430px;
    }
    .topBox {
        // background: var(--theme-color);
        height: 200px;
        padding: 50px 100px;
        .redPrice{
            // color: rgba(191, 28, 28, 1);
            font-size: 20px;
        }
        p{
            margin: 0;
            margin-top: 20px;
        }
    }

    .region {
        width: 900px;
        // height: 100%;
        background: white;

        // .rg {
        //     // height: calc(100vh - 80px);
        // }
    }
}

@media screen and (max-width: 750PX) {
    .payImg{
        display: none;
    }
    ::v-deep .el-dialog{
        width: 300px !important;
    }
    .topBox {
        background: var(--banner-color);
        height: 150px;
        padding: 30px 40px;
        p{
            margin: 0;
            margin-top: 10px;
        }
    }

    .region {
        width: 100%;
        // height: 100vh;
    }
}
</style>