// 微信支付
import service from '@/utils/request'
// 获取订单二维码
export function getCode( planId,data) {
  return service({
    url: `/wx-pay/native/${planId}`,
    method: 'post',
    data
  });
}
// 查询支付状态
export function payStatus( number,params) {
    return service({
      url: `/orders/status/${number}`,
      method: 'get',
      params
    });
  }
  // H5订单支付
  export function payTel( planId,data) {
    return service({
      url: `/wx-pay/h5/${planId}`,
      method: 'post',
      data
    });
}
  // 公众号支付
  export function payWeChat( planId,data) {
    return service({
      url: `/wx-pay/js/${planId}`,
      method: 'post',
      data
    });
}